import { ApiResponseTypes } from 'services/utils'
import { SubscriptionPlanTypes } from '../subscriptionPlans'

type SubscriptionPlanUuidTypes = SubscriptionPlanTypes['uuid']

export const PAYMENT_METHOD_ID = {
  STRIPE: 1,
  VIPPS: 2
} as const

export type PaymentMethodIdTypes =
  (typeof PAYMENT_METHOD_ID)[keyof typeof PAYMENT_METHOD_ID]

interface CheckoutFormTypes {
  password: string
  subscriptionPlanUuid: SubscriptionPlanUuidTypes
  paymentMethodId: PaymentMethodIdTypes
  promotionCode: string | null
  licenseQuantity: number
}

type MinifiedParentTypes = {
  fullName: string
  email: string
  agreedNewsletter: boolean
  agreedTerms: boolean
}

export type ChildrenAvatarIndexTypes = '-1' | '0' | '1' | '2' | '3' | '4'

export type ChildrenAccountTypes = {
  profileId: string
  loginId: string
  avatarIndex: ChildrenAvatarIndexTypes
  avatarName: string
}

export type ParentCheckoutTypes = CheckoutFormTypes &
  MinifiedParentTypes &
  Partial<ChildrenAccountTypes>

export interface ParentTypes extends MinifiedParentTypes {
  createdAt: Date
  updatedAt: Date
  deletedAt?: Date
  active: boolean
  parentId: number
  uuid: string
  firstName: string
  lastName: string
  phone: string
  street: string
  zipCode?: string
  intercomId?: string
  fusionAuthFamilyId?: string
  postOffice?: string
}

interface PaymentTypes {
  paymentMethodId: PaymentMethodIdTypes
  checkoutSessionId: string
  parentUuId: string
  description: string
  status: string
  totalValue: string
  discountValue: number
  discountPercent: number
  deletedAt?: Date
  valueCharged?: unknown
  promotionCodeId?: string
  observation?: string
  paymentCustomerId?: string
  vippsTransactionId?: string
  createdAt: Date
  updatedAt: Date
  active: boolean
  id: number
}

interface SubscriptionTypes {
  active: boolean
  parentUuid: string
  pinId: string
  expirationDate: Date
  subscriptionPlanUuid: SubscriptionPlanUuidTypes
  recurring: number
  paymentTransaction: PaymentTypes[]
  deletedAt?: Date
  createdAt: Date
  updatedAt: Date
  id: number
}

interface PaymentSessionTypes {
  sessionId: string
  vipsCheckoutUrl: string
  paymentMethod: string
}

interface ParentCheckoutDataTypes {
  parentData: ParentTypes
  subscriptionData: SubscriptionTypes[]
  paymentData: PaymentTypes
  paymentSession: PaymentSessionTypes
}

export interface ParentPayloadTypes {
  payload: ParentCheckoutTypes
  state: string
}

export type ParentResponseTypes = ApiResponseTypes<ParentCheckoutDataTypes>
