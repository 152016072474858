import { apiParent } from 'services'
import { ParentPayloadTypes, ParentResponseTypes } from './Parent.types'

export const postParent = async ({ state, payload }: ParentPayloadTypes) => {
  const { data: response } = await apiParent.post<ParentResponseTypes>(
    `/parent?state=${state}`,
    payload
  )

  return response
}

export default {
  post: postParent
}
