import { useMemo } from 'react'
import { useMutation } from 'react-query'
import { ParentServices, PaymentServices } from 'services'

export const usePaymentQuery = () => {
  const postParentQuery = useMutation(ParentServices.post, {
    mutationKey: 'payment/parent'
  })

  const agreementQuery = useMutation(PaymentServices.agreement.get, {
    mutationKey: 'payment/agreement'
  })

  return useMemo(
    () => ({
      postParentQuery,
      agreementQuery
    }),
    [postParentQuery, agreementQuery]
  )
}
