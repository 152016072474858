import { apiParent } from 'services'
import { PaymentDataTypes } from './Payment.types'

export const getAgreement = async (paymentId: string) => {
  const { data: response } = await apiParent.get<[PaymentDataTypes]>(
    `/payment/agreement/${paymentId}`
  )
  return response
}

export default {
  agreement: {
    get: getAgreement
  }
}
